<template>
  <div>
    <div class="boxCol2-2 flexB">
      <div class="box statistics-box">
        <h2 class="bold">
          {{ $t("site-statistics-searching-period") }} ({{ $t("site-statistics-title-compare-prev") }}
          <span
              :class="{
              blue: Number(ration > 0) ? true : false,
              red: Number(ration < 0) ? true : false,
            }"
          >{{ ration }}%</span
          >)
        </h2>
        <div class="worksite-table">
          <table v-if="resultValue == 'worksite1' || resultValue == 'worksite2'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th>
                {{
                  resultValue == "worksite1"
                      ? $t("site-statistics-column-outbreaks")
                      : resultValue == "worksite2"
                      ? $t("site-statistics-column-live-time")
                      : ""
                }}
              </th>
            </tr>
            <tr class="total">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ refTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in refLiveData" :key="i">
              <td>{{ item.date }}</td>
              <td>
                {{
                  item.totalData != undefined ? item.totalData : item.totalTime
                }}
              </td>
            </tr>
          </table>
          <table v-if="resultValue == 'worksite3'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th colspan="3">{{ $t("site-statistics-column-all-user") }}</th>
            </tr>
            <tr class="workColumn">
              <th></th>
              <th>{{ $t("site-statistics-column-mem") }}</th>
              <th>{{ $t("site-statistics-column-operator") }}</th>
              <th>{{ $t("site-statistics-column-total") }}</th>
            </tr>
            <tr class="total total2">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ refSumSup }}</td>
              <td>{{ refSumCtrl }}</td>
              <td>{{ refTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in refLiveData" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.support }}</td>
              <td>{{ item.controller }}</td>
              <td>{{ item.totalData }}</td>
            </tr>
          </table>
          <table v-if="resultValue == 'worksite4'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th colspan="3">{{ $t("338") }}</th>
            </tr>
            <tr>
              <th></th>
              <th>LIVE</th>
              <th>VOD</th>
              <th>{{ $t("site-statistics-column-total") }}</th>
            </tr>
            <tr class="total">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ refSumLive }}</td>
              <td>{{ refSumVod }}</td>
              <td>{{ refTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in refVideoData" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.live }}</td>
              <td>{{ item.vod }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="box statistics-box">
        <h2 class="bold">{{ $t("site-statistics-title-compare") }}</h2>
        <div class="worksite-table">
          <table v-if="resultValue == 'worksite1' || resultValue == 'worksite2'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th>
                {{
                  resultValue == "worksite1"
                      ? $t("site-statistics-column-outbreaks")
                      : resultValue == "worksite2"
                      ? $t("site-statistics-column-live-time")
                      : ""
                }}
              </th>
            </tr>
            <tr class="total">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ preTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in preLiveData" :key="i">
              <td>{{ item.date }}</td>
              <td>
                {{
                  item.totalData != undefined ? item.totalData : item.totalTime
                }}
              </td>
            </tr>
          </table>
          <table v-if="resultValue == 'worksite3'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th colspan="3">{{ $t("site-statistics-column-all-user") }}</th>
            </tr>
            <tr class="workColumn">
              <th></th>
              <th>{{ $t("site-statistics-column-mem") }}</th>
              <th>{{ $t("site-statistics-column-operator") }}</th>
              <th>{{ $t("site-statistics-column-total") }}</th>
            </tr>
            <tr class="total total2">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ preSumSup }}</td>
              <td>{{ preSumCtrl }}</td>
              <td>{{ preTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in preLiveData" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.support }}</td>
              <td>{{ item.controller }}</td>
              <td>{{ item.totalData }}</td>
            </tr>
          </table>
          <table v-if="resultValue == 'worksite4'">
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th>
              <th colspan="3">{{ $t("338") }}</th>
            </tr>
            <tr>
              <th></th>
              <th>LIVE</th>
              <th>VOD</th>
              <th>{{ $t("site-statistics-column-total") }}</th>
            </tr>
            <tr class="total">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ preSumLive }}</td>
              <td>{{ preSumVod }}</td>
              <td>{{ preTotalData }}</td>
            </tr>
            <tr v-for="(item, i) in preVideoData" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.live }}</td>
              <td>{{ item.vod }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Worksite1",
  props: {
    refTotalData: {
      type: Number,
    },
    preTotalData: {
      type: Number,
    },
    refLiveData: {
      type: Array,
    },
    preLiveData: {
      type: Array,
    },
    refVideoData: {
      type: Array,
    },
    preVideoData: {
      type: Array,
    },
    ration: {
      type: Number,
    },
    resultValue: {
      type: String,
    },
    refSumSup: {
      type: Number,
    },
    refSumCtrl: {
      type: Number,
    },
    preSumSup: {
      type: Number,
    },
    preSumCtrl: {
      type: Number,
    },
    refSumLive: {
      type: Number,
    },
    refSumVod: {
      type: Number,
    },
    preSumLive: {
      type: Number,
    },
    preSumVod: {
      type: Number,
    },
  },
};
</script>
